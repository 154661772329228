import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout';
import ArticleCard from '../components/article-card'
import PageTransition from 'gatsby-plugin-page-transitions';
import { InView } from 'react-intersection-observer';
import Testimonials from '../components/testimonials'
import Press from '../components/press'

const ArticlePageTemplate = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
          filter: {fields: {collection: {eq: "articles"}}}
          sort: {fields: frontmatter___date, order: DESC}
        ) {
        nodes {
          ...MarkdownDataPreviewArticles
        }
      }
    }
  `)

  const articles = data.allMdx.nodes;
  const [inView, setInView] = React.useState(false)

  const meta = {
    type: 'website',
    url: `https://www.ultrafine.co${location.pathname}`
  }

  const schema = `{
    "@context": "https://schema.org",
    "@type": "Blog",
    "url": "https://www.ultrafine.co/articles",
    "name": "Ultrafine Articles",
    "about": "Ultrafine Articles",
    "blogPosts": [
      ${
        data.allMdx.nodes.map((node) => (
        `
          {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": "${node.frontmatter.title}",
            "url": "https://www.ultrafine.co/articles/${node.slug}",
            "datePublished": "${node.frontmatter.date}",
            "image": "https://www.ultrafine.co${node.frontmatter.hero_image.publicURL}",
            "description": "${node.excerpt}",
            "author": {
              "@type": "Organization",
              "name": "Ultrafine",
              "url": "https://www.ultrafine.co"
            },
            "publisher": {
              "@type": "Organization",
              "name": "Ultrafine",
              "logo": {
                "type": "ImageObject",
                "url": "https://www.ultrafine.co/ultrafine-logo.jpg"
              }
            }
          }
        `
        ))
      }
    ]
  }`;

  const content = () => {
    if (articles.length > 0) {
      return (
        <div className={`articles-page grid animate${inView ? ' fade-in': ''}`}>
          <h1 className="articles-page__title h2">Our insights</h1>
          <ul className="subgrid-list list-unstyled" role="list">
            {
              data.allMdx.nodes.map((node) => (
                <li className="subgrid-list__list-item" key={node.id}>
                  <ArticleCard article={node} />
                </li>
              ))
            }
          </ul>
        </div>
      )
    } else {
      return (
        <div className={`articles-page articles-page--no-content grid animate${inView ? ' fade-in' : ''}`}>
          <div className="articles-page__no-content">
            <h1>Coming soon</h1>
            <p className="articles-page__text">Our team are hard at work. Please check back later!</p>
          </div>
        </div>
      )
    }
  }


  return (
    <Layout pageTitle="Our Insights" pageClass="template-articles" location={location} meta={meta} schema={schema}>
      <PageTransition>
        <InView as="section" onChange={setInView} initialInView="true" delay="200">
          <div className="container">
            {content()}
          </div>
        </InView>
        <Testimonials />
        <Press />
      </PageTransition>
    </Layout>
  )
}

export default ArticlePageTemplate
