import * as React from 'react'
import ArticlePageTemplate from '../../templates/article-page-template'
import WorkPageTemplate from '../../templates/work-page-template'

const ContentIndex = ({ location }) => {
  const collection = location.pathname.split('/')[1]

  const templates = {
    articles: <ArticlePageTemplate location={location} />,
    work: <WorkPageTemplate location={location} />,
  }

  return (
    <div location={location}>
      {templates[collection] ? templates[collection] : null}
    </div>
  )
}

export default ContentIndex