import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../components/layout';
import PageTransition from 'gatsby-plugin-page-transitions';
import { InView } from 'react-intersection-observer';
import Testimonials from '../components/testimonials'
import Press from '../components/press'

const WorkPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
          filter: {fields: {collection: {eq: "work"}}}
          sort: {fields: frontmatter___date, order: DESC}
        ) {
        nodes {
          ...MarkdownDataPreviewArticles
        }
      }
    }
  `)

  const work = data.allMdx.nodes;
  const [inView, setInView] = React.useState(false)

  const meta = {
    type: 'website',
    url: `https://www.ultrafine.co${location.pathname}`
  }


  const content = () => {
    if (work.length > 0) {
      return (
        <div className={`work-page grid animate${inView ? ' fade-in': ''}`}>
          <h1 className="work-page__title h2 sr-only">Our work</h1>
          <ul className="work-page__subgrid list-unstyled" role="list">
            {
              data.allMdx.nodes.map((node) => (
                <li className="work-page__list-item" key={node.id}>
                  <Link to={`/work/${node.slug}`} className="increase-target">
                    <span className='sr-only'>{node.frontmatter.title}</span>
                  </Link>
                  <GatsbyImage
                    image={getImage(node.frontmatter.hero_image)}
                    alt={node.frontmatter.hero_image_alt}
                    height="560"
                    width="560"
                  />
                  <div className="work-page__item-title-wrapper">
                    <h2 className="work-page__item-title">{node.frontmatter.title}</h2>
                    <h3 className="work-page__item-subtitle">{node.frontmatter.subtitle}</h3>
                   </div>
                </li>
              ))
            }
          </ul>
        </div>
      )
    } else {
      return (
        <div className={`work-page work-page--no-content grid animate${inView ? ' fade-in' : ''}`}>
          <div className="work-page__no-content">
            <h1>Coming soon</h1>
            <p className="work-page__text">Our team are hard at work. Please check back later!</p>
          </div>
        </div>
      )
    }
  }

  return (
    <Layout pageTitle="Our Work" pageClass="template-works" location={location}>
      <PageTransition>
        <InView as="section" onChange={setInView} initialInView="true" delay="200">
          <div className="container">
            {content()}
          </div>
        </InView>
        <Testimonials />
        <Press />
      </PageTransition>
    </Layout>
  )
}

export default WorkPage
